/*
Author: Paolo Furini
Component: src/components/PlaceList/PlaceList.tsx
*/

import { FunctionComponent } from 'react';
import List, { ListProps } from '@material-ui/core/List';
import { useIntl } from 'react-intl';
import PlaceListItem, {
  PlaceListItemProps,
  MapProps as TMapProps,
} from '../PlaceListItem';

export type MapProps = TMapProps;

export interface PlaceItemListData extends PlaceListItemProps {}

export interface PlaceListProps extends ListProps {
  items: PlaceItemListData[];
  mapProps?: MapProps;
}

const PlaceList: FunctionComponent<PlaceListProps> = (props) => {
  const { items, mapProps, ...listProps } = props;
  const intl = useIntl();

  return (
    <List
      aria-label={intl.formatMessage({
        id: 'PlaceList_places_aria',
        defaultMessage: 'Luoghi',
      })}
      aria-roledescription={intl.formatMessage({
        id: 'PlaceList_places_ariadesc',
        defaultMessage: "Lista dei luoghi associati all'azienda",
      })}
      {...listProps}
    >
      {items.map((item) => (
        <PlaceListItem key={item.name} mapProps={mapProps} {...item} />
      ))}
    </List>
  );
};

PlaceList.displayName = 'PlaceList';

export default PlaceList;
