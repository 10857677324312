/*
Author: Paolo Furini
Component: src/components/FullscreenProgress/FullscreenProgress.tsx
*/

import { FunctionComponent } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import logo from 'assets/img/logoSmall.png';
import Typography from '@material-ui/core/Typography';
import { useIntl } from 'react-intl';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100vh',
    padding: 32,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    marginBottom: '5rem',
    maxWidth: '100%',
  },
  text: {
    marginBottom: '2rem',
  },
});

const FullscreenProgress: FunctionComponent = () => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.root}>
      <img alt="Bio Sociale" src={logo} className={classes.logo} />
      <Typography variant="h2" color="secondary" align="center" className={classes.text}>
        {intl.formatMessage({
          id: 'FullscreenProgress_loading',
          defaultMessage: 'Recupero dati in Blockchain ...',
        })}
      </Typography>
      <CircularProgress color="secondary" />
    </div>
  );
};
FullscreenProgress.displayName = 'FullscreenProgress';

export default FullscreenProgress;
