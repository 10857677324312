/*
Author: Paolo Furini
Component: src/components/LotMetadataListItem/LotMetadataListItem.tsx
*/

import { FunctionComponent } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export interface LotMetadataListItemProps {
  code: string;
  name: string;
  value?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardContent: {
      '&:last-child': {
        paddingBottom: theme.spacing(2),
      },
    },
  }),
);

const LotMetadataListItem: FunctionComponent<LotMetadataListItemProps> = ({
  name,
  value,
}) => {
  const classes = useStyles();

  return (
    <Card variant="outlined">
      <CardContent className={classes.cardContent}>
        <Typography
          variant="button"
          color="textSecondary"
          gutterBottom
          display="block"
          noWrap
        >
          {name}
        </Typography>
        <Box mt={1}>
          <Typography variant="body2">{value}</Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

LotMetadataListItem.displayName = 'LotMetadataListItem';

export default LotMetadataListItem;
