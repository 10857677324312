import { useContext } from 'react';
import { BlockchainService } from '../services/blockchainService';
import BlockchainContext from '../services/blockchainContext';

function useBlockchain(): BlockchainService {
  const context = useContext(BlockchainContext);
  if (!context)
    throw new Error('Missing BlockchainProvider in the tree');
  return context;
}

export default useBlockchain;
