export class DateType {

  private _value: Date;

  constructor(value: string | number | Date) {
    this._value = new Date(value);
  }

  toDateString(): string {
    return new Intl.DateTimeFormat().format(this._value);
  }

  toDateTimeString(): string {
    return new Intl.DateTimeFormat().format(this._value);
  }
}
