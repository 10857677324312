/*
Author: Paolo Furini
Component: src/components/LotMetadataList/LotMetadataList.tsx
*/

import { FunctionComponent } from 'react';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import LotMetadataListItem, {
  LotMetadataListItemProps,
} from 'components/LotMetadataListItem';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core';

export interface LotMetadataListItemData extends LotMetadataListItemProps {}

export interface LotMetadataListProps {
  items: LotMetadataListItemData[];
  cols?: number;
}

const LotMetadataList: FunctionComponent<LotMetadataListProps> = ({
  items,
  cols,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  let columns = cols;
  if (cols === undefined) {
    columns = matches ? 3 : 2;
  }

  return (
    <GridList cellHeight="auto" cols={columns} style={{ flexGrow: 1 }}>
      {items.map((item) => (
        <GridListTile key={item.code}>
          <LotMetadataListItem {...item} />
        </GridListTile>
      ))}
    </GridList>
  );
};

LotMetadataList.displayName = 'LotMetadataList';

export default LotMetadataList;
