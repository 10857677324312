const AbiStructs: {[index: string]: unknown} = {
  Lotto: [{
    'components': [
      {
        'internalType': 'bytes20',
        'name': 'dataProduzione',
        'type': 'bytes20',
      },
      {
        'internalType': 'bytes20',
        'name': 'dataSpedizione',
        'type': 'bytes20',
      },
      {
        'internalType': 'bytes20',
        'name': 'dataConsegna',
        'type': 'bytes20',
      },
      {
        'internalType': 'uint32',
        'name': 'quantita',
        'type': 'uint32',
      },
      {
        'internalType': 'string',
        'name': 'numero',
        'type': 'string',
      },
      {
        'internalType': 'string',
        'name': 'numeroDdt',
        'type': 'string',
      },
      {
        'internalType': 'string',
        'name': 'urlDdt',
        'type': 'string',
      },
      {
        'internalType': 'string',
        'name': 'metadata',
        'type': 'string',
      },
    ],
    'internalType': 'struct BioSociale.Lotto',
    'type': 'tuple',
    'baseType': 'tuple',
  }],
  Prodotto: [{
    'components': [
      {
        'internalType': 'bytes32',
        'name': 'categoria',
        'type': 'bytes32',
      },
      {
        'internalType': 'string',
        'name': 'descrizioneCategoria',
        'type': 'string',
      },
      {
        'internalType': 'string',
        'name': 'nome',
        'type': 'string',
      },
      {
        'internalType': 'string',
        'name': 'descrizione',
        'type': 'string',
      },
      {
        'internalType': 'string',
        'name': 'urlDescrizioneEstesa',
        'type': 'string',
      },
      {
        'internalType': 'string',
        'name': 'urlImmagine',
        'type': 'string',
      },
    ],
    'internalType': 'struct BioSociale.Prodotto',
    'type': 'tuple',
    'baseType': 'tuple',
  }],
  Azienda: [{
    'components': [
      {
        'internalType': 'string',
        'name': 'ragioneSociale',
        'type': 'string',
      },
      {
        'internalType': 'string',
        'name': 'sedeLegale',
        'type': 'string',
      },
      {
        'internalType': 'string',
        'name': 'descrizione',
        'type': 'string',
      },
      {
        'internalType': 'string',
        'name': 'urlDescrizioneEstesa',
        'type': 'string',
      },
      {
        'internalType': 'string',
        'name': 'urlLogo',
        'type': 'string',
      },
      {
        'internalType': 'string',
        'name': 'urlImmagine',
        'type': 'string',
      },
      {
        'internalType': 'string',
        'name': 'urlCertificazione',
        'type': 'string',
      },
      {
        'internalType': 'bytes32',
        'name': 'partitaIva',
        'type': 'bytes32',
      },
      {
        'internalType': 'bytes32',
        'name': 'codiceFiscale',
        'type': 'bytes32',
      },
    ],
    'internalType': 'struct BioSociale.Azienda',
    'type': 'tuple',
    'baseType': 'tuple',
  }],
  Luogo: [{
    'components': [
      {
        'internalType': 'string',
        'name': 'nome',
        'type': 'string',
      },
      {
        'internalType': 'string',
        'name': 'indirizzo',
        'type': 'string',
      },
      {
        'internalType': 'string',
        'name': 'urlImmagine',
        'type': 'string',
      },
      {
        'internalType': 'bytes1',
        'name': 'tipo',
        'type': 'bytes1',
      },
      {
        'internalType': 'int32',
        'name': 'lat',
        'type': 'int32',
      },
      {
        'internalType': 'int32',
        'name': 'lon',
        'type': 'int32',
      },
    ],
    'internalType': 'struct BioSociale.Luogo',
    'type': 'tuple',
    'baseType': 'tuple',
  }],
};

export default AbiStructs;
