/*
Author: Paolo Furini
Component: src/components/ImageWithInlineText/ImageWithInlineText.tsx
*/

import { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box, { BoxProps } from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core';
import { TypographyProps } from '@material-ui/system';
import { Config } from 'dompurify';
import Image from 'material-ui-image';
import HtmlSnippet from '../HtmlSnippet';

export interface ImageInlineTextProps extends BoxProps {
  imageUrl?: string;
  imageSize?: number;
  imageAlt?: string;
}

const useStyles = makeStyles({
  text: {
    '&:after': {
      clear: 'both',
    },
  },
});

const purifyConfig: Config = {
  ALLOWED_TAGS: [
    'p',
    'br',
    'b',
    'strong',
    'i',
    'em',
    'mark',
    'code',
    'small',
    'sub',
    'sup',
    'del',
    'ins',
    'q',
    'abbr',
  ],
};

const ImageInlineText: FunctionComponent<ImageInlineTextProps> = (props) => {
  const { imageUrl, imageSize = 100, imageAlt, children, ...boxProps } = props;
  const classes = useStyles();
  const theme = useTheme();
  const fontProps = theme.typography.body1;
  const typographyProps: TypographyProps = {
    fontFamily: fontProps.fontFamily,
    fontStyle: fontProps.fontStyle,
    fontSize: fontProps.fontSize,
    fontWeight: fontProps.fontWeight,
    lineHeight: fontProps.lineHeight,
    letterSpacing: fontProps.letterSpacing,
    textAlign: fontProps.textAlign,
  };

  return (
    <Box display="inline" {...boxProps}>
      {imageUrl && (
        <Image
          src={imageUrl}
          alt={imageAlt}
          width={imageSize}
          style={{
            width: imageSize,
            paddingTop: imageSize,
            float: 'left',
            marginRight: theme.spacing(2),
            marginBottom: theme.spacing(1),
          }}
          cover
        />
      )}
      <HtmlSnippet
        {...typographyProps}
        purifyConfig={purifyConfig}
        className={classes.text}
      >
        {children}
      </HtmlSnippet>
    </Box>
  );
};

ImageInlineText.displayName = 'ImageInlineText';

export default ImageInlineText;
