/*
Author: Paolo Furini
Component: src/components/PageHeader/PageHeader.tsx
*/

import { memo, ReactElement } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import logo from 'assets/img/logoSmall.png';
import placeholderImage from 'assets/img/logo.png';
import BackButton from 'components/BackButton';
import Image from 'material-ui-image';

const DEFAULT_HEIGHT = 325;
const MAX_TITLE_LINES = 3;

export interface PageHeaderProps {
  imageUrl?: string;
  title: string;
  showBackButton?: boolean;
  showLogo?: boolean;
  height?: number;
  maxTitleLines?: number;
}

type StyleProps = { height?: number; maxTitleLines?: number };

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      position: 'relative',
      width: '100%',
      height: (props: StyleProps) => props.height ?? DEFAULT_HEIGHT,
    },
    overlay: {
      color: theme.palette.primary.contrastText,
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: 10,
      background:
        'radial-gradient(75px 75px at 0% 0%, rgba(0, 0, 0, 0.55) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0) 46.5%, rgba(0, 0, 0, 0.7) 84.56%, rgba(0, 0, 0, 0.8) 100%)',
    },
    logo: {
      position: 'absolute',
      width: 120,
      [theme.breakpoints.up('md')]: {
        width: 192,
      },
      height: 'auto',
      right: theme.spacing(3),
      top: theme.spacing(2),
      opacity: 0.3,
      filter: 'saturate(0) drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.25))',
    },
    backButton: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
    title: {
      position: 'absolute',
      maxHeight: (props: StyleProps) =>
        `calc(${theme.typography.h1.lineHeight}em * ${
          props.maxTitleLines ?? MAX_TITLE_LINES
        })`,
      left: 0,
      bottom: theme.spacing(2),
      right: 0,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      overflow: 'hidden',
    },
  }),
);

function PageHeader({
  imageUrl = placeholderImage,
  title,
  showBackButton = true,
  showLogo = true,
  height = DEFAULT_HEIGHT,
  maxTitleLines = MAX_TITLE_LINES,
}: PageHeaderProps): ReactElement {
  const classes = useStyles({ height, maxTitleLines });

  return (
    <header className={classes.header}>
      <Image
        src={imageUrl!}
        aspectRatio={1.6}
        alt={title}
        style={{
          position: 'absolute',
          width: '100%',
          paddingTop: height,
        }}
        cover
      />
      <div className={classes.overlay}>
        {showBackButton ? <BackButton className={classes.backButton} /> : null}
        {showLogo ? (
          <img className={classes.logo} src={logo} alt="Bio Sociale" />
        ) : null}
        <Typography
          classes={{ root: classes.title }}
          variant="h1"
          display="block"
          align="right"
        >
          {title}
        </Typography>
      </div>
    </header>
  );
}

PageHeader.displayName = 'PageHeader';

PageHeader.defaultProps = {
  imageUrl: placeholderImage,
  showBackButton: true,
  showLogo: true,
  height: DEFAULT_HEIGHT,
  maxTitleLines: MAX_TITLE_LINES,
};

export default memo(PageHeader);
