/*
Author: Paolo Furini
Component: src/components/OrderItemPage/OrderItemPage.tsx
*/

import { FunctionComponent } from 'react';
import PageHeader from 'components/PageHeader/PageHeader';
import DetailsCard from 'components/DetailsCard';
import CompositionList from 'components/CompositionList';
import Accordion from 'components/Accordion';
import TrackingSteps from 'components/TrackingSteps';
import FormatListBulletedOutlinedIcon from '@material-ui/icons/FormatListBulletedOutlined';
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
import AttachmentList from 'components/AttachmentList';
import ImageInlineText from 'components/ImageInlineText';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import clsx from 'clsx';
import { useAxios } from 'use-axios-client';
import { Order } from 'models/order';
import apiInstance from 'services/apiInstance';
import { useHistory, useParams } from 'react-router-dom';
import openInNewTab from 'helpers/openInNewTab';
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';
import SendIcon from '@material-ui/icons/Send';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { useIntl } from 'react-intl';
import makePoint from 'helpers/makePoint';
import useMapProps from 'hooks/useMapProps';
import FullscreenProgress from 'components/FullscreenProgress';
import FullscreenError from 'components/FullscreenError';

export interface OrderItemPageProps {
  // TODO: props definition here
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      flexGrow: 1,
    },
    tracking: {
      paddingTop: theme.spacing(1),
    },
    attachments: {
      paddingTop: 0,
    },
  }),
);

const OrderItemPage: FunctionComponent<OrderItemPageProps> = () => {
  const classes = useStyle();
  const theme = useTheme();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { data, error, loading } = useAxios<Order>({
    axiosInstance: apiInstance,
    url: `${id}.json`,
  });
  const intl = useIntl();
  const mapProps = useMapProps();

  let content = null;
  if (data && !loading) {
    const order = data!;
    const orderLine = order.lines[0];
    const product = orderLine.productGroup || orderLine.lot!.product;
    const { supplier } = orderLine;
    const lots = orderLine.items
      ?.filter((item) => !!item.lot)
      .map((item) => item.lot!);
    const detailsText = intl.formatMessage(
      {
        id: 'OrderItemPage_whatYouAreLookingAt_content',
        defaultMessage:
          '<b>{product}</b> preparato da <b>{supplier}</b> il <b>{prepareDate}</b> (ordine numero <b>{order}</b>) e consegnato il <b>{deliveryDate}</b>.',
      },
      {
        product: product.name,
        supplier: supplier.name,
        prepareDate: orderLine.prepareDate,
        order: order.number,
        deliveryDate: orderLine.closeDate,
        b: (...chunks) => <b>{chunks}</b>,
      },
    );

    content = (
      <>
        <PageHeader title={product.name} imageUrl={product.imageUrl} />
        <List component="main">
          <ListItem component="section">
            <DetailsCard
              heading={intl.formatMessage({
                id: 'OrderItemPage_whatYouAreLookingAt',
                defaultMessage: 'Cosa stai guardando',
              })}
              className={classes.listItem}
            >
              <ImageInlineText>{detailsText}</ImageInlineText>
            </DetailsCard>
          </ListItem>
          {lots && lots.length > 0 && (
            <ListItem component="section">
              <DetailsCard
                heading={intl.formatMessage({
                  id: 'OrderItemPage_composition',
                  defaultMessage: 'Composizione',
                })}
                className={classes.listItem}
              >
                <CompositionList
                  items={lots.map((lot) => ({
                    id: lot.id,
                    title: lot.product.name,
                    subtitle: `di ${lot.product.producer.name}`,
                    imageUrl: lot.product.imageUrl,
                    onClick: () => history.push(`/lot/${lot.id}`),
                  }))}
                />
              </DetailsCard>
            </ListItem>
          )}
          <ListItem component="section">
            <Accordion
              heading={intl.formatMessage({
                id: 'OrderItemPage_tracking',
                defaultMessage: 'Tracciatura',
              })}
              icon={<FormatListBulletedOutlinedIcon />}
              className={classes.listItem}
              defaultExpanded
            >
              <TrackingSteps
                className={clsx(classes.listItem, classes.tracking)}
                mapProps={mapProps}
                steps={[
                  {
                    id: 3,
                    name: intl.formatMessage({
                      id: 'OrderItemPage_delivery',
                      defaultMessage: 'Consegna',
                    }),
                    icon: <LocalShippingIcon />,
                    avatarColor: theme.palette.error.light,
                    date: orderLine.closeDate.toDateString(),
                    style: {
                      minHeight: 65,
                    },
                  },
                  {
                    id: 2,
                    name: intl.formatMessage({
                      id: 'OrderItemPage_shipment',
                      defaultMessage: 'Spedizione',
                    }),
                    icon: <SendIcon />,
                    avatarColor: theme.palette.info.light,
                    date: orderLine.shipDate.toDateString(),
                    subject: {
                      name: orderLine.supplier.name,
                      place: orderLine.shipPlace?.name,
                      address: orderLine.shipPlace?.address,
                      addressCoords: makePoint(orderLine.shipPlace?.coords),
                    },
                    onSubjectClick: () =>
                      history.push(`/supplier/${orderLine.supplier.id}`),
                  },
                  {
                    id: 1,
                    name: intl.formatMessage({
                      id: 'OrderItemPage_preparation',
                      defaultMessage: 'Preparazione',
                    }),
                    icon: <MoveToInboxIcon />,
                    avatarColor: theme.palette.success.light,
                    date: orderLine.prepareDate.toDateString(),
                    subject: {
                      name: orderLine.supplier.name,
                      place: orderLine.preparePlace?.name,
                      address: orderLine.preparePlace?.address,
                      addressCoords: makePoint(orderLine.preparePlace?.coords),
                    },
                    onSubjectClick: () =>
                      history.push(`/supplier/${orderLine.supplier.id}`),
                  },
                ]}
              />
            </Accordion>
          </ListItem>
          {orderLine.ddtUrl && (
            <ListItem component="section">
              <Accordion
                heading={intl.formatMessage({
                  id: 'OrderItemPage_attachments',
                  defaultMessage: 'Allegati',
                })}
                icon={<LibraryBooksOutlinedIcon />}
                className={classes.listItem}
              >
                <AttachmentList
                  className={clsx(classes.listItem, classes.attachments)}
                  items={[
                    {
                      id: 1,
                      name: intl.formatMessage({
                        id: 'OrderItemPage_ddtt',
                        defaultMessage: 'Documento di Trasporto',
                      }),
                      onClick: () => openInNewTab(orderLine.ddtUrl!),
                    },
                  ]}
                />
              </Accordion>
            </ListItem>
          )}
        </List>
      </>
    );
  }

  return (
    <>
      {loading && <FullscreenProgress />}
      {error && <FullscreenError error={error} />}
      {content}
    </>
  );
};

OrderItemPage.displayName = 'OrderItemPage';

export default OrderItemPage;
