/*
Author: Paolo Furini
Component: src/components/FullscreenError/FullscreenError.tsx
*/

import { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { AxiosError } from 'axios';
import { useIntl } from 'react-intl';
import logo from 'assets/img/logoSmall.png';
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100vh',
    padding: 32,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  logo: {
    maxWidth: '100%',
  },
  error: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: {
    fontSize: '6rem',
  },
});

export interface FullscreenErrorProps {
  error: Error;
}

const FullscreenError: FunctionComponent<FullscreenErrorProps> = ({
  error,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const axiosError = error as AxiosError;
  let { message } = error;
  if (axiosError.isAxiosError) {
    switch (axiosError.response?.status) {
      case 400:
        message = intl.formatMessage({
          id: 'error_400',
          defaultMessage: 'Il codice richiesto non è valido.',
        });
        break;
      case 404:
        message = intl.formatMessage({
          id: 'error_404',
          defaultMessage: 'Il codice richiesto non esiste.',
        });
        break;
    }
  }

  return (
    <div className={classes.root}>
      <img alt="Bio Sociale" src={logo} className={classes.logo} />
      <div className={classes.error}>
        <ErrorIcon color="error" className={classes.icon} />
        <Typography variant="h1" color="error" align="center">
          {message}
        </Typography>
      </div>
    </div>
  );
};

FullscreenError.displayName = 'FullscreenError';

export default FullscreenError;
