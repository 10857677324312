/*
Author: Paolo Furini
Component: src/components/TrackingSteps/TrackingSteps.tsx
*/

import { FunctionComponent } from 'react';
import TrackingStep, {
  TrackingStepProps,
  MapProps as TMapProps,
} from 'components/TrackingStep';
import Box, { BoxProps } from '@material-ui/core/Box';

export type MapProps = TMapProps;

export interface TrackingStepData extends TrackingStepProps {
  id: string | number;
}

export interface TrackingStepsProps extends BoxProps {
  steps: TrackingStepData[];
  mapProps?: MapProps;
}

const TrackingSteps: FunctionComponent<TrackingStepsProps> = (props) => {
  const { steps, mapProps, ...boxProps } = props;

  return (
    <Box {...boxProps}>
      {steps.map((step) => (
        <TrackingStep key={step.id} mapProps={mapProps} {...step} />
      ))}
    </Box>
  );
};

TrackingSteps.displayName = 'TrackingSteps';

export default TrackingSteps;
