/*
Author: Paolo Furini
Component: src/components/CompositionList/CompositionList.tsx
*/

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CompositionListItem, {
  CompositionListItemProps,
} from 'components/CompositionListItem';
import { FunctionComponent } from 'react';
import List from '@material-ui/core/List';
import { useIntl } from 'react-intl';

export interface CompositionListItemData extends CompositionListItemProps {
  id: string | number;
}

export interface CompositionListProps {
  items: CompositionListItemData[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderLeft: `1px solid ${theme.palette.divider}`,
      borderTop: `1px solid ${theme.palette.divider}`,
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  }),
);

const CompositionList: FunctionComponent<CompositionListProps> = ({
  items,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <List
      className={classes.root}
      aria-label={intl.formatMessage({
        id: 'CompositionList_productComposition',
        defaultMessage: 'Composizione prodotto',
      })}
      disablePadding
    >
      {items.map((item) => (
        <CompositionListItem key={item.id} {...item} />
      ))}
    </List>
  );
};

CompositionList.displayName = 'CompositionList';

export default CompositionList;
