/*
Author: Paolo Furini
Component: src/components/SubjectDetails/SubjectDetails.tsx
*/

import { FunctionComponent } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import MapThumbnail, {
  Point,
  MapProps as TMapProps,
} from 'components/MapThumbnail';
import { useIntl } from 'react-intl';

export type MapProps = TMapProps;

export interface SubjectDetailsProps {
  name: string;
  place?: string;
  address?: string;
  addressCoords?: Point;
  mapProps?: MapProps;
  onClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      gridTemplateColumns: 'min-content 1fr',
      gridTemplateRows: 'min-content 1fr',
      gridTemplateAreas: '"nameIcon name" "placeIcon place"',
      gap: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    },
    nameIcon: {
      gridArea: 'nameIcon',
      alignSelf: 'center',
      color: theme.palette.text.secondary,
    },
    name: {
      gridArea: 'name',
      alignSelf: 'center',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      display: 'flex',
      alignItems: 'center',
    },
    placeIcon: {
      gridArea: 'placeIcon',
      color: theme.palette.text.secondary,
    },
    place: {
      gridArea: 'place',
      overflow: 'hidden',
    },
  }),
);

const SubjectDetails: FunctionComponent<SubjectDetailsProps> = ({
  name,
  place,
  address,
  addressCoords,
  mapProps,
  onClick,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.root}>
      <div className={classes.nameIcon}>
        <BusinessOutlinedIcon />
      </div>
      <div className={classes.name}>
        <Typography noWrap>{name}</Typography>
        <IconButton
          color="primary"
          edge="end"
          size="small"
          aria-label={intl.formatMessage({
            id: 'SubjectDetails_details_aria',
            defaultMessage: 'Dettagli',
          })}
          onClick={onClick}
        >
          <ArrowForwardOutlinedIcon />
        </IconButton>
      </div>
      {place && (
        <div className={classes.placeIcon}>
          <RoomOutlinedIcon />
        </div>
      )}
      <div className={classes.place}>
        {place && (
          <Grid container spacing={1}>
            <Grid item xs zeroMinWidth>
              <Typography variant="subtitle2" noWrap>
                {place}
              </Typography>
              <Typography variant="body2">{address}</Typography>
            </Grid>
            {addressCoords && (
              <Grid item>
                <MapThumbnail
                  placeName={place}
                  coords={addressCoords}
                  mapProps={mapProps}
                />
              </Grid>
            )}
          </Grid>
        )}
      </div>
    </div>
  );
};

SubjectDetails.displayName = 'SubjectDetails';

export default SubjectDetails;
