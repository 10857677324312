import { parseLanguages } from 'helpers/localeUtils';
import messages from './messages';

const DEFAULT_LANG = 'it';

const availableLocales = Object.keys(messages);
const userLocale = parseLanguages(availableLocales, DEFAULT_LANG);
const intlConfig = {
  locale: userLocale,
  defaultLocale: DEFAULT_LANG,
  messages: (messages as { [index: string]: Record<string, string> })[
    userLocale
  ],
};

const locales = {
  availableLocales,
  userLocale,
  defaultLocale: DEFAULT_LANG,
  messages,
  intlConfig,
};

export default locales;
