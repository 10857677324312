/*
Author: Paolo Furini
Component: src/components/HtmlSnippet/HtmlSnippet.tsx
*/

import { FunctionComponent, memo } from 'react';
import DOMPurify, { Config } from 'dompurify';
import parse from 'html-react-parser';
import Box, { BoxProps } from '@material-ui/core/Box';

export interface HtmlSnippetProps extends BoxProps {
  /**
   * Remove whitespace from HTML.
   *
   * @see https://github.com/remarkablemark/html-react-parser#trim
   */
  trim?: boolean;
  /**
   * Options for DOMPurify.
   *
   * NOTE: these options cannot be set and are overridden by default:
   * - RETURN_DOM
   * - RETURN_DOM_FRAGMENT
   * - RETURN_DOM_IMPORT
   * - RETURN_TRUSTED_TYPE
   * - WHOLE_DOCUMENT
   * - SANITIZE_DOM
   * - FORCE_BODY
   *
   * @see https://github.com/cure53/DOMPurify#can-i-configure-dompurify
   */
  purifyConfig?: Config;
}

const purifyConfigOverrides: Config = {
  RETURN_DOM: false,
  RETURN_DOM_FRAGMENT: false,
  RETURN_DOM_IMPORT: false,
  RETURN_TRUSTED_TYPE: false,
  WHOLE_DOCUMENT: false,
  SANITIZE_DOM: true,
  FORCE_BODY: false,
};

const HtmlSnippet: FunctionComponent<HtmlSnippetProps> = memo((props) => {
  const { children, trim, purifyConfig, ...boxProps } = props;

  let safeChildren = children;
  if (typeof children === 'string') {
    safeChildren = parse(
      DOMPurify.sanitize(children, {
        ...purifyConfig,
        ...purifyConfigOverrides,
      }) as string,
      { trim },
    );
  }

  return <Box {...boxProps}>{safeChildren}</Box>;
});

HtmlSnippet.displayName = 'HtmlSnippet';

export default HtmlSnippet;
