import Container from '@material-ui/core/Container';
import { Switch, Route, useLocation } from 'react-router-dom';
// @ts-ignore
import SlideRoutes from 'react-slide-routes';
import routes from './config/routes';

function App() {
  const location = useLocation();

  return (
    <Container
      maxWidth="md"
      style={{ minHeight: '100vh', minWidth: 350, background: 'white' }}
      disableGutters
    >
      <Switch>
        <SlideRoutes location={location} animation="slide">
          {routes.map((route) => (
            <Route key={route.path} {...route} />
          ))}
        </SlideRoutes>
      </Switch>
    </Container>
  );
}

export default App;
