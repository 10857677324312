import { useEffect, useState } from 'react';
import useBlockchain from './useBlockchain';
import { Company } from '../models/company';

function useCompany(hash: string, path: string) {
  const [data, setData] = useState<Company | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const blockchain = useBlockchain();

  useEffect(() => {
    async function getData(fetchFn: () => Promise<Company>) {
      setLoading(true);
      try {
        const company = await fetchFn();
        setData(company);
        setError(null);
      } catch (e) {
        setData(null);
        setError(e);
      } finally {
        setLoading(false);
      }
    }

    let fetchFn;
    if (path.indexOf('/producer/') > -1) {
      fetchFn = blockchain.getProducer.bind(blockchain, hash);
    } else if (path.indexOf('/supplier/') > -1) {
      fetchFn = blockchain.getSupplier.bind(blockchain, hash);
    } else {
      throw new Error('Tipo di azienda sconosciuto');
    }
    getData(fetchFn);
  }, [hash, path, blockchain]);

  return { data, loading, error };
}

export default useCompany;
