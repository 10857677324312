/*
Author: Paolo Furini
Component: src/components/Balloon/Balloon.tsx
*/

import { CSSProperties, FunctionComponent, ReactNode } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

export interface BalloonProps {
  width?: number;
  arrowSize?: number;
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'block',
      boxSizing: 'border-box',
      background: theme.palette.background.default,
      filter: 'drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.25))',
      position: 'relative',
      width: ({ width = 100 }: BalloonProps) => width,
      height: 'auto',
      marginBottom: ({ arrowSize = 13 }: BalloonProps) => arrowSize,
      '&:after': {
        position: 'absolute',
        boxSizing: 'border-box',
        display: 'block',
        width: 0,
        height: 0,
        content: '""',
        left: '50%',
        top: '100%',
        transform: 'translate(-50%, 0)',
        transformOrigin: 'top',
        borderTop: ({ arrowSize = 13 }: BalloonProps) =>
          `${arrowSize}px solid ${theme.palette.background.default}`,
        borderRight: ({ arrowSize = 13 }: BalloonProps) =>
          `${arrowSize}px solid transparent`,
        borderBottom: ({ arrowSize = 13 }: BalloonProps) =>
          `${arrowSize}px solid transparent`,
        borderLeft: ({ arrowSize = 13 }: BalloonProps) =>
          `${arrowSize}px solid transparent`,
      },
      // '&:before': {
      //   position: 'absolute',
      //   display: 'block',
      //   width: 0,
      //   height: 0,
      //   content: '""',
      //   left: '50%',
      //   top: '100%',
      //   transform: 'translate(-50%, 0)',
      //   transformOrigin: 'top',
      //   borderTop: ({ arrowSize = 13 }: BalloonProps) =>
      //     `${arrowSize}px solid ${theme.palette.divider}`,
      //   borderRight: ({ arrowSize = 13 }: BalloonProps) =>
      //     `${arrowSize}px solid transparent`,
      //   borderBottom: ({ arrowSize = 13 }: BalloonProps) =>
      //     `${arrowSize}px solid transparent`,
      //   borderLeft: ({ arrowSize = 13 }: BalloonProps) =>
      //     `${arrowSize}px solid transparent`,
      // },
    },
  }),
);

const Balloon: FunctionComponent<BalloonProps> = (props) => {
  const { className, style, children, ...styleProps } = props;
  const classes = useStyles(styleProps);

  return (
    <div className={clsx(classes.root, className)} style={style}>
      {children}
    </div>
  );
};

Balloon.displayName = 'Balloon';

export default Balloon;
