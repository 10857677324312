import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { IntlProvider } from 'react-intl';
import locales from 'config/locales';
import { Router } from 'react-router-dom';
import { wrapHistory } from 'oaf-react-router/dist';
import { createHashHistory } from 'history';
import theme from './config/theme';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QuorumBlockchainProvider } from './services/quorum/quorumBlockchainProvider';

// Material-UI default typography configuration only relies on 300, 400, 500, and 700 font weights.
// see: https://github.com/fontsource/fontsource/blob/master/packages/roboto/README.md
import '@fontsource/roboto/latin-300.css';
import '@fontsource/roboto/latin-400.css';
import '@fontsource/roboto/latin-500.css';
import '@fontsource/roboto/latin-700.css';

const history = createHashHistory();
wrapHistory(history, {
  restorePageStateOnPop: false,
  smoothScroll: true,
  primaryFocusTarget: 'body',
});

ReactDOM.render(
  <StrictMode>
    <IntlProvider {...locales.intlConfig}>
      <QuorumBlockchainProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router history={history}>
            <App />
          </Router>
        </ThemeProvider>
      </QuorumBlockchainProvider>
    </IntlProvider>
  </StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
