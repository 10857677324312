/*
Author: Paolo Furini
Component: src/components/RemoteHtmlSection/RemoteHtmlSection.tsx
*/

import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import { useAxios } from 'use-axios-client';
import HtmlSnippet from '../HtmlSnippet';

export interface RemoteHtmlSectionProps {
  documentUrl: string;
}

const useStyle = makeStyles({
  listItem: {
    flexGrow: 1,
  },
});

const RemoteHtmlSection: FunctionComponent<RemoteHtmlSectionProps> = ({
  documentUrl,
}) => {
  const classes = useStyle();
  const { data: html, error, loading } = useAxios<string>(documentUrl, {
    responseType: 'text',
  });

  return (
    <ListItem component="section" className={classes.listItem}>
      {loading && 'Caricando ...'}
      {error && error.message}
      {html && !loading && <HtmlSnippet>{html}</HtmlSnippet>}
    </ListItem>
  );
};

RemoteHtmlSection.displayName = 'RemoteHtmlSection';

export default RemoteHtmlSection;
