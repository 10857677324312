import OrderItemPage from 'pages/OrderItemPage';
import ProductLotPage from 'pages/ProductLotPage';
import CompanyPage from 'pages/CompanyPage';

const routes = [
  { path: '/item/:id', component: OrderItemPage, order: 1 },
  { path: '/lot/:id', component: ProductLotPage, order: 2 },
  { path: '/producer/:id', component: CompanyPage, order: 3 },
  { path: '/supplier/:id', component: CompanyPage, order: 3 },
  {
    path: '*',
    component: () => {
      window.location.replace('https://www.biosociale.it');
      return null;
    },
    order: 0,
  },
];

export default routes;
