import { useIntl } from 'react-intl';

const useMapProps = () => {
  const intl = useIntl();

  return process.env.NODE_ENV === 'production'
    ? {
        key: process.env.REACT_APP_MAPS_KEY!,
        language: intl.locale,
        region: 'IT',
      }
    : undefined;
};

export default useMapProps;
