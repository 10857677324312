/*
Author: Paolo Furini
Component: src/components/ProductLotPage/ProductLotPage.tsx
*/

import { FunctionComponent } from 'react';
import PageHeader from 'components/PageHeader/PageHeader';
import List from '@material-ui/core/List';
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import RemoteHtmlSection from 'components/RemoteHtmlSection';
import ProductLotSection from 'components/ProductLotSection';
import useMapProps from 'hooks/useMapProps';
import FullscreenProgress from 'components/FullscreenProgress';
import FullscreenError from 'components/FullscreenError';
import useLot from '../../hooks/useLot';

export interface ProductLotPageProps {
  // TODO: props definition here
}

const ProductLotPage: FunctionComponent<ProductLotPageProps> = () => {
  const { path, url } = useRouteMatch();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const mapProps = useMapProps();

  const { data, error, loading } = useLot(id);

  function navigateToDetails() {
    history.push(`${url}/details`);
  }

  function navigateToProducer(companyId: string) {
    history.push(`/producer/${companyId}`);
  }

  function navigateToSupplier(companyId: string) {
    history.push(`/supplier/${companyId}`);
  }

  return (
    <>
      {loading && <FullscreenProgress />}
      {error && <FullscreenError error={error} />}
      {data && !loading && (
        <>
          <PageHeader
            title={data.product.name}
            imageUrl={data.product.imageUrl}
          />
          <List component="main">
            {data.product.descriptionUrl ? (
              <Switch>
                <Route
                  path={`${path}/details`}
                  render={() => (
                    <RemoteHtmlSection
                      documentUrl={data.product.descriptionUrl!}
                    />
                  )}
                />
                <Route
                  path={`${path}`}
                  render={() => (
                    <ProductLotSection
                      lot={data}
                      onDetailsClick={navigateToDetails}
                      onProducerClick={navigateToProducer}
                      onSupplierClick={navigateToSupplier}
                      mapProps={mapProps}
                    />
                  )}
                />
              </Switch>
            ) : (
              <ProductLotSection
                lot={data}
                onProducerClick={navigateToProducer}
                onSupplierClick={navigateToSupplier}
                mapProps={mapProps}
              />
            )}
          </List>
        </>
      )}
    </>
  );
};

ProductLotPage.displayName = 'ProductLotPage';

export default ProductLotPage;
