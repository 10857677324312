/*
Author: Paolo Furini
Component: src/components/AttachmentList/AttachmentList.tsx
*/

import { FunctionComponent } from 'react';
import List, { ListProps } from '@material-ui/core/List';
import AttachmentListItem, {
  AttachmentListItemProps,
} from 'components/AttachmentListItem';
import { useIntl } from 'react-intl';

export interface AttachmentListItemData extends AttachmentListItemProps {
  id: string | number;
}

export interface AttachmentListProps extends ListProps {
  items: AttachmentListItemData[];
}

const AttachmentList: FunctionComponent<AttachmentListProps> = (props) => {
  const { items, ...listProps } = props;
  const intl = useIntl();

  return (
    <List
      aria-label={intl.formatMessage({
        id: 'AttachmentListItemData_attachments_aria',
        defaultMessage: 'Documenti allegati',
      })}
      {...listProps}
    >
      {items.map((item) => (
        <AttachmentListItem key={item.id} {...item} />
      ))}
    </List>
  );
};

AttachmentList.displayName = 'AttachmentList';

export default AttachmentList;
