/*
Author: Paolo Furini
Component: src/components/DetailsCard/DetailsCard.tsx
*/

import { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card, { CardProps } from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';

export interface DetailsCardProps extends CardProps {
  heading: string;
  showDetailsButton?: boolean;
  onClick?: () => void;
}

const useStyles = makeStyles({ actions: { justifyContent: 'flex-end' } });

const DetailsCard: FunctionComponent<DetailsCardProps> = (props) => {
  const { heading, showDetailsButton, onClick, children, ...cardProps } = props;
  const classes = useStyles();

  return (
    <Card variant="outlined" {...cardProps}>
      <CardContent>
        <Typography
          variant="button"
          color="textSecondary"
          gutterBottom
          display="block"
          noWrap
        >
          {heading}
        </Typography>
        <Box mt={1}>{children}</Box>
      </CardContent>
      {showDetailsButton && (
        <CardActions className={classes.actions}>
          <Button size="small" color="primary" onClick={onClick}>
            <FormattedMessage
              id="DetailsCard_details"
              defaultMessage="Dettagli"
            />
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

DetailsCard.displayName = 'DetailsCard';
export default DetailsCard;
