/*
Author: Paolo Furini
Component: src/components/PlaceListItem/PlaceListItem.tsx
*/

import React, { FunctionComponent, ReactElement } from 'react';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import { useTheme } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Image from 'material-ui-image';
import { useIntl } from 'react-intl';
import MapWithPin, { MapProps as TMapProps } from '../MapWithPin';

export type MapProps = TMapProps;

export interface PlaceListItemProps {
  name: string;
  icon?: ReactElement; // to accept only elements and not primitives like string
  address: string;
  addressPoint: { lat: number; lng: number };
  imageUrl?: string;
  mapProps?: MapProps;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    icon: {
      margin: 0,
      color: theme.palette.text.secondary,
      fontSize: 32,
      lineHeight: 0,
      height: 32,
      width: 32,
      '&>*': {
        fontSize: 32,
      },
    },
    actions: {
      justifyContent: 'center',
      padding: theme.spacing(2),
    },
    address: {
      textAlign: 'center',
    },
  }),
);

const PlaceListItem: FunctionComponent<PlaceListItemProps> = ({
  name,
  icon,
  address,
  addressPoint,
  imageUrl,
  mapProps,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const intl = useIntl();

  // @ts-ignore
  return (
    <ListItem
      disableGutters
      aria-label={intl.formatMessage({
        id: 'PlaceListItem_place_aria',
        defaultMessage: 'Luogo',
      })}
      aria-roledescription={intl.formatMessage({
        id: 'PlaceListItem_place_ariadesc',
        defaultMessage: "Luogo associato all'azienda",
      })}
    >
      <Card variant="outlined" className={classes.root}>
        <CardHeader
          title={name}
          action={icon}
          titleTypographyProps={{
            variant: 'h4',
            display: 'block',
            noWrap: true,
            'aria-label': intl.formatMessage({
              id: 'PlaceListItem_name_aria',
              defaultMessage: 'Nome',
            }),
          }}
          classes={{ action: classes.icon }}
          aria-hidden={false}
        />
        <MapWithPin
          center={{ ...addressPoint }}
          zoom={16}
          height={280}
          pinProps={{ width: 130, style: { padding: theme.spacing(1) } }}
          mapProps={mapProps}
          aria-hidden
        >
          {imageUrl && <Image src={imageUrl} cover />}
        </MapWithPin>
        <CardActions
          className={classes.actions}
          disableSpacing
          aria-hidden={false}
        >
          <Typography
            variant="body2"
            color="textSecondary"
            aria-label={intl.formatMessage({
              id: 'PlaceListItem_address_aria',
              defaultMessage: 'Indirizzo',
            })}
            className={classes.address}
          >
            {address}
          </Typography>
        </CardActions>
      </Card>
    </ListItem>
  );
};

PlaceListItem.displayName = 'PlaceListItem';

export default PlaceListItem;
