const messages = {
  AttachmentListItemData_attachments_aria: 'Attached documents',
  AttachmentListItem_open_aria: 'Open',
  BackButton_back_aria: 'Back',
  CompanyCard_businessAddress: 'Business Addr.',
  CompanyCard_businessName: 'Business Name',
  CompanyCard_taxId: 'Fiscal Code',
  CompanyCard_vatId: 'VAT id',
  CompanySection_attachments: 'Attachments',
  CompanySection_companyDetails: 'Company Details',
  CompanySection_places: 'Places',
  CompanySection_producerCard: 'Producer Details',
  CompanySection_qualityCertification: 'Certificate of Quality',
  CompositionListItem_details_aria: 'Details',
  CompositionList_productComposition: 'Product composition',
  DetailsCard_details: 'Details',
  MapThumbnail_close_aria: 'Close',
  OrderItemPage_attachments: 'Attachments',
  OrderItemPage_composition: 'Composition',
  OrderItemPage_ddtt: 'Shipping Document',
  OrderItemPage_delivery: 'Delivery',
  OrderItemPage_preparation: 'Preparation',
  OrderItemPage_shipment: 'Shipment',
  OrderItemPage_tracking: 'Tracking',
  OrderItemPage_whatYouAreLookingAt: 'What you are looking at',
  OrderItemPage_whatYouAreLookingAt_content:
    '<b>{product}</b> prepared by <b>{supplier}</b> on <b>{prepareDate}</b> (order number <b>{order}</b>) and delivered on <b>{deliveryDate}</b>.',
  PlaceListItem_address_aria: 'Address',
  PlaceListItem_name_aria: 'Name',
  PlaceListItem_place_aria: 'Place',
  PlaceListItem_place_ariadesc: 'Place of the business',
  PlaceList_places_aria: 'Places',
  PlaceList_places_ariadesc: 'Places where the business operates',
  ProductLotSection_attachments: 'Attachments',
  ProductLotSection_date: 'Date',
  ProductLotSection_ddt: 'Shipping Document',
  ProductLotSection_ddtNumber: 'DDT Number',
  ProductLotSection_category: 'Category',
  ProductLotSection_delivery: 'Delivery',
  ProductLotSection_expireDate: 'Expire Date',
  ProductLotSection_harvesting: 'Harvesting',
  ProductLotSection_lotNumber: 'Lot #{lotNumber}',
  ProductLotSection_producer: 'Producer',
  ProductLotSection_productCard: 'Product Card',
  ProductLotSection_tracking: 'Tracking',
  ProductLotSection_warehousing: 'Warehousing',
  SubjectDetails_details_aria: 'Details',
  error_400: 'The requested code is invalid.',
  error_404: 'The requested code does not exist.',
};
export default messages;
