/*
Author: Paolo Furini
Component: src/components/MapThumbnail/MapThumbnail.tsx
*/

import { FunctionComponent, ReactNode, useState } from 'react';
import mapThumb from 'assets/mapThumb.svg';
import Dialog from '@material-ui/core/Dialog';
import ButtonBase from '@material-ui/core/ButtonBase';
import GoogleMapReact from 'google-map-react';
import Box from '@material-ui/core/Box';
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core/styles';
import PinIcon from 'icons/Pin';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useIntl } from 'react-intl';

export type Point = { lat: number; lng: number };

export type MapProps = { key: string; language?: string; region?: string };

export interface MapThumbnailProps {
  coords: Point;
  placeName?: string;
  zoom?: number;
  mapProps?: MapProps;
}

const Pin = (props: Point) => (
  <PinIcon
    {...props}
    width={48}
    height={48}
    fill="lightgreen"
    style={{ transform: 'translate(-50%, -100%)' }}
  />
);

const titleStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    title: {
      paddingRight: 40,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof titleStyles> {
  id: string;
  children: ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(titleStyles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  const intl = useIntl();

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" noWrap className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label={intl.formatMessage({
            id: 'MapThumbnail_close_aria',
            defaultMessage: 'Chiudi',
          })}
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles({ root: { padding: 0 } })(MuiDialogContent);

const MapThumbnail: FunctionComponent<MapThumbnailProps> = ({
  coords,
  placeName,
  zoom = 16,
  mapProps,
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <ButtonBase onClick={handleClickOpen} title={placeName}>
        <img alt={placeName ?? ''} src={mapThumb} />
      </ButtonBase>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="map-thumbnail-title"
        style={{ minWidth: 300 }}
        fullWidth
      >
        <DialogTitle id="map-thumbnail-title" onClose={handleClose}>
          {placeName}
        </DialogTitle>
        <DialogContent dividers>
          <Box width="100%" height={300}>
            <GoogleMapReact
              bootstrapURLKeys={mapProps}
              defaultCenter={coords}
              defaultZoom={zoom}
              options={{ mapTypeId: 'hybrid' }}
            >
              <Pin lat={coords.lat} lng={coords.lng} />
            </GoogleMapReact>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

MapThumbnail.displayName = 'MapThumbnail';

export default MapThumbnail;
