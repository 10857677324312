import { FunctionComponent, useRef } from 'react';
import BlockchainContext from '../blockchainContext';
import { QuorumBlockchainService } from './quorumBlockchainService';
import { BlockchainService } from '../blockchainService';

export const QuorumBlockchainProvider: FunctionComponent = ({ children }) => {
  const service = useRef<BlockchainService>(new QuorumBlockchainService());
  return (
    <BlockchainContext.Provider value={service.current}>
      {children}
    </BlockchainContext.Provider>
  );
};
