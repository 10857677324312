/*
Author: Paolo Furini
Component: src/components/CompanySection/CompanySection.tsx
*/

import React, { FunctionComponent } from 'react';
import { Company } from 'models/company';
import ListItem from '@material-ui/core/ListItem';
import SatelliteOutlinedIcon from '@material-ui/icons/SatelliteOutlined';
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import openInNewTab from 'helpers/openInNewTab';
import DetailsCard from 'components/DetailsCard';
import ImageInlineText from 'components/ImageInlineText';
import CompanyCard from 'components/CompanyCard';
import Accordion from 'components/Accordion';
import PlaceList, { MapProps } from 'components/PlaceList';
import AttachmentList, {
  AttachmentListItemData,
} from 'components/AttachmentList';
import makePoint from 'helpers/makePoint';
import { useIntl } from 'react-intl';
import GrassIcon from 'icons/Grass';
import HomeWorkIcon from '@material-ui/icons/HomeWork';

export interface CompanySectionProps {
  company: Company;
  onDetailsClick?: () => void;
  mapProps?: MapProps;
}

const useStyle = makeStyles({
  listItem: {
    flexGrow: 1,
  },
  attachments: {
    paddingTop: 0,
  },
});

const CompanySection: FunctionComponent<CompanySectionProps> = ({
  company,
  onDetailsClick,
  mapProps,
}) => {
  const classes = useStyle();
  const intl = useIntl();

  const attachments: AttachmentListItemData[] = [];
  if (company.certificationUrl) {
    attachments.push({
      id: 'quality',
      name: intl.formatMessage({
        id: 'CompanySection_qualityCertification',
        defaultMessage: 'Certificato di Qualità',
      }),
      onClick: () => openInNewTab(company.certificationUrl!),
    });
  }

  return (
    <>
      <ListItem component="section" className={classes.listItem}>
        <DetailsCard
          heading={intl.formatMessage({
            id: 'CompanySection_producerCard',
            defaultMessage: 'Scheda Produttore',
          })}
          className={classes.listItem}
          onClick={onDetailsClick}
          showDetailsButton={!!company.descriptionUrl}
        >
          <ImageInlineText imageUrl={company.logoUrl}>
            {company.summary}
          </ImageInlineText>
        </DetailsCard>
      </ListItem>
      <ListItem component="section">
        <DetailsCard
          heading={intl.formatMessage({
            id: 'CompanySection_companyDetails',
            defaultMessage: 'Dati Azienda',
          })}
          className={classes.listItem}
        >
          <CompanyCard
            name={company.name}
            vatId={company.vatId}
            taxId={company.taxId}
            address={company.address}
            logoUrl={company.logoUrl}
            mapProps={mapProps}
          />
        </DetailsCard>
      </ListItem>
      {(company.places?.length ?? 0) > 0 && (
        <ListItem component="section" className={classes.listItem}>
          <Accordion
            heading={intl.formatMessage({
              id: 'CompanySection_places',
              defaultMessage: 'Luoghi',
            })}
            icon={<SatelliteOutlinedIcon />}
            className={classes.listItem}
          >
            <PlaceList
              className={classes.listItem}
              items={company.places!.map((place) => ({
                name: place.name,
                address: place.address,
                imageUrl: place.imageUrl,
                addressPoint: makePoint(place.coords)!,
                icon: place.type === 'P' ? <GrassIcon /> : <HomeWorkIcon />,
              }))}
              mapProps={mapProps}
            />
          </Accordion>
        </ListItem>
      )}
      {attachments.length > 0 && (
        <ListItem component="section" className={classes.listItem}>
          <Accordion
            heading={intl.formatMessage({
              id: 'CompanySection_attachments',
              defaultMessage: 'Allegati',
            })}
            icon={<LibraryBooksOutlinedIcon />}
            className={classes.listItem}
          >
            <AttachmentList
              className={clsx(classes.listItem, classes.attachments)}
              items={attachments}
            />
          </Accordion>
        </ListItem>
      )}
    </>
  );
};

CompanySection.displayName = 'CompanySection';

export default CompanySection;
