/*
Author: Paolo Furini
Component: src/components/AttachmentListItem/AttachmentListItem.tsx
*/

import { FunctionComponent } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ListItem } from '@material-ui/core';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import { useIntl } from 'react-intl';

export interface AttachmentListItemProps {
  name: string;
  onClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      color: theme.palette.getContrastText(theme.palette.grey[400]),
      backgroundColor: theme.palette.grey[400],
    },
    action: {
      right: 0,
    },
  }),
);

const AttachmentListItem: FunctionComponent<AttachmentListItemProps> = ({
  name,
  onClick,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <ListItem alignItems="center" disableGutters>
      <ListItemAvatar>
        <Avatar className={classes.avatar}>
          <PictureAsPdfIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={name} />
      <ListItemSecondaryAction>
        <IconButton
          color="primary"
          edge="end"
          aria-label={intl.formatMessage({
            id: 'AttachmentListItem_open_aria',
            defaultMessage: 'Apri',
          })}
          onClick={onClick}
        >
          <ArrowForwardOutlinedIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

AttachmentListItem.displayName = 'AttachmentListItem';

export default AttachmentListItem;
