/*
Author: Paolo Furini
Component: src/components/MapWithPin/MapWithPin.tsx
*/

import React, { FunctionComponent } from 'react';
import GoogleMapReact from 'google-map-react';
import Box, { BoxProps } from '@material-ui/core/Box';
import PinIcon from 'icons/Pin';
import Balloon, { BalloonProps } from '../Balloon';

type Point = { lat: number; lng: number };

type PinProps = Partial<Point> & BalloonProps;

export type MapProps = { key: string; language?: string; region?: string };

export interface MapWithPinProps extends BoxProps {
  center: Point;
  zoom?: number;
  pinProps?: PinProps;
  mapProps?: MapProps;
}

const Pin = (props: PinProps) =>
  props.children ? (
    <Balloon
      {...props}
      style={{ ...props.style, transform: 'translate(-50%, -100%)' }}
    >
      {props.children}
    </Balloon>
  ) : (
    <PinIcon
      {...props}
      width={48}
      height={48}
      fill="lightgreen"
      style={{ ...props.style, transform: 'translate(-50%, -100%)' }}
    />
  );

const MapWithPin: FunctionComponent<MapWithPinProps> = (props) => {
  const {
    center,
    zoom = 11,
    children,
    pinProps,
    mapProps,
    ...boxProps
  } = props;

  return (
    <Box height="100%" width="100%" {...boxProps}>
      <GoogleMapReact
        bootstrapURLKeys={mapProps}
        defaultCenter={center}
        defaultZoom={zoom}
        options={{ mapTypeId: 'hybrid' }}
      >
        <Pin lat={center.lat} lng={center.lng} {...pinProps}>
          {children}
        </Pin>
      </GoogleMapReact>
    </Box>
  );
};

MapWithPin.displayName = 'MapWithPin';

export default MapWithPin;
