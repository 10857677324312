import { useEffect, useState } from 'react';
import { Lot } from '../models/lot';
import useBlockchain from './useBlockchain';

function useLot(hash: string) {
  const [data, setData] = useState<Lot | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const blockchain = useBlockchain();

  useEffect(() => {
    async function getData() {
      setLoading(true);
      try {
        const lot = await blockchain.getLot(hash);
        setData(lot);
        setError(null);
      } catch (e) {
        setData(null);
        setError(e);
      } finally {
        setLoading(false);
      }
    }

    getData();
  }, [hash, blockchain]);

  return { data, loading, error };
}

export default useLot;
