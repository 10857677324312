const getUsersPreferredLanguages = () => {
  if (navigator.languages !== undefined) {
    return navigator.languages;
  }
  if (navigator.language !== undefined) {
    return [navigator.language];
  }
  return undefined;
};

export const parseLanguages = (
  acceptedLangs: string[],
  defaultLang: string,
) => {
  const userPref = getUsersPreferredLanguages();
  const match = userPref
    ? userPref.find((lang) => acceptedLangs.includes(lang))
    : undefined;

  return match ?? defaultLang;
};
