/*
Author: Paolo Furini
Component: src/components/ProductLotSection/ProductLotSection.tsx
*/

import { FunctionComponent } from 'react';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import ListItem from '@material-ui/core/ListItem';
import LoyaltyOutlinedIcon from '@material-ui/icons/LoyaltyOutlined';
import FormatListBulletedOutlinedIcon from '@material-ui/icons/FormatListBulletedOutlined';
import clsx from 'clsx';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import { Lot } from 'models/lot';
import AttachmentList, {
  AttachmentListItemData,
} from 'components/AttachmentList';
import openInNewTab from 'helpers/openInNewTab';
import DetailsCard from 'components/DetailsCard';
import ImageInlineText from 'components/ImageInlineText';
import AvatarTitleSubtitle from 'components/AvatarTitleSubtitle';
import Accordion from 'components/Accordion';
import LotMetadataList from 'components/LotMetadataList';
import TrackingSteps, { MapProps } from 'components/TrackingSteps';
import GrassIcon from 'icons/Grass';
import makePoint from 'helpers/makePoint';
import { useIntl } from 'react-intl';

export interface ProductLotSectionProps {
  lot: Lot;
  onDetailsClick?: () => void;
  onProducerClick?: (id: string) => void;
  onSupplierClick?: (id: string) => void;
  mapProps?: MapProps;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      flexGrow: 1,
    },
    tracking: {
      paddingTop: theme.spacing(1),
    },
    attachments: {
      paddingTop: 0,
    },
  }),
);

const ProductLotSection: FunctionComponent<ProductLotSectionProps> = ({
  lot,
  onDetailsClick,
  onProducerClick,
  onSupplierClick,
  mapProps,
}) => {
  const classes = useStyle();
  const theme = useTheme();
  const intl = useIntl();

  let { metadata = [] } = lot;

  // translate metadata names dynamically by looking up message strings from the items code
  metadata = metadata.map((item) => ({
    code: item.code,
    name: intl.formatMessage({
      id: `ProductLotSection_meta_${item.code}`,
      defaultMessage: item.name,
    }),
    value: item.value,
  }));

  if (lot.ddtNumber) {
    metadata = [
      {
        code: '_DDTNUM',
        name: intl.formatMessage({
          id: 'ProductLotSection_ddtNumber',
          defaultMessage: 'Numero DDT',
        }),
        value: lot.ddtNumber as string,
      },
      ...metadata,
    ];
  }
  if (lot.product.category || lot.product.categoryDesc) {
    metadata = [
      {
        code: '_CATEGORY',
        name: intl.formatMessage({
          id: 'ProductLotSection_category',
          defaultMessage: 'Categoria',
        }),
        value: lot.product.categoryDesc || lot.product.category!,
      },
      ...metadata,
    ];
  }
  metadata = [
    {
      code: '_PREPDATE',
      name: intl.formatMessage({
        id: 'ProductLotSection_date',
        defaultMessage: 'Data',
      }),
      value: lot.prepareDate.toDateString(),
    },
    ...metadata,
  ];

  const attachments: AttachmentListItemData[] = [];
  if (lot.ddtUrl) {
    attachments.push({
      id: 'ddt',
      name: intl.formatMessage({
        id: 'ProductLotSection_ddt',
        defaultMessage: 'Documento di Trasporto',
      }),
      onClick: () => openInNewTab(lot.ddtUrl!),
    });
  }

  return (
    <>
      <ListItem component="section">
        <DetailsCard
          heading={intl.formatMessage({
            id: 'ProductLotSection_productCard',
            defaultMessage: 'Scheda Prodotto',
          })}
          className={classes.listItem}
          onClick={onDetailsClick}
          showDetailsButton={!!lot.product.descriptionUrl}
        >
          <ImageInlineText>{lot.product.summary}</ImageInlineText>
        </DetailsCard>
      </ListItem>
      <ListItem component="section">
        <DetailsCard
          heading={intl.formatMessage({
            id: 'ProductLotSection_producer',
            defaultMessage: 'Produttore',
          })}
          className={classes.listItem}
          onClick={onProducerClick?.bind(null, lot.product.producer.id)}
          showDetailsButton
        >
          <AvatarTitleSubtitle
            title={lot.product.producer.name}
            subtitle={lot.product.producer.address}
            avatar={lot.product.producer.logoUrl}
            avatarSize={80}
          />
        </DetailsCard>
      </ListItem>
      <ListItem component="section">
        <Accordion
          heading={intl.formatMessage(
            {
              id: 'ProductLotSection_lotNumber',
              defaultMessage: 'Lotto #{lotNumber}',
            },
            { lotNumber: lot.number },
          )}
          icon={<LoyaltyOutlinedIcon />}
          className={classes.listItem}
        >
          <LotMetadataList items={metadata} />
        </Accordion>
      </ListItem>
      <ListItem component="section">
        <Accordion
          heading={intl.formatMessage({
            id: 'ProductLotSection_tracking',
            defaultMessage: 'Tracciatura',
          })}
          icon={<FormatListBulletedOutlinedIcon />}
          className={classes.listItem}
          defaultExpanded
        >
          <TrackingSteps
            className={clsx(classes.listItem, classes.tracking)}
            mapProps={mapProps}
            steps={[
              {
                id: 1,
                name: intl.formatMessage({
                  id: 'ProductLotSection_harvesting',
                  defaultMessage: 'Raccolta',
                }),
                icon: <GrassIcon />,
                avatarColor: theme.palette.success.light,
                date: lot.prepareDate.toDateString(),
                subject: {
                  name: lot.product.producer.name,
                  place: lot.preparePlace?.name,
                  address: lot.preparePlace?.address,
                  addressCoords: makePoint(lot.preparePlace?.coords),
                },
                onSubjectClick: onProducerClick?.bind(
                  null,
                  lot.product.producer.id,
                ),
              },
              {
                id: 2,
                name: intl.formatMessage({
                  id: 'ProductLotSection_warehousing',
                  defaultMessage: 'Stoccaggio',
                }),
                icon: <HomeWorkIcon />,
                avatarColor: theme.palette.info.light,
                date: lot.shipDate.toDateString(),
                subject: {
                  name: lot.product.producer.name,
                  place: lot.shipPlace?.name,
                  address: lot.shipPlace?.address,
                  addressCoords: makePoint(lot.shipPlace?.coords),
                },
                onSubjectClick: onProducerClick?.bind(
                  null,
                  lot.product.producer.id,
                ),
              },
              {
                id: 3,
                name: intl.formatMessage({
                  id: 'ProductLotSection_delivery',
                  defaultMessage: 'Consegna',
                }),
                icon: <LocalShippingIcon />,
                avatarColor: theme.palette.error.light,
                date: lot.deliveryDate.toDateString(),
                subject: {
                  name: lot.supplier.name,
                  place: lot.deliveryPlace?.name,
                  address: lot.deliveryPlace?.address,
                  addressCoords: makePoint(lot.deliveryPlace?.coords),
                },
                onSubjectClick: onSupplierClick?.bind(this, lot.supplier.id),
              },
            ]}
          />
        </Accordion>
      </ListItem>
      {attachments.length > 0 && (
        <ListItem component="section">
          <Accordion
            heading={intl.formatMessage({
              id: 'ProductLotSection_attachments',
              defaultMessage: 'Allegati',
            })}
            icon={<LibraryBooksOutlinedIcon />}
            className={classes.listItem}
          >
            <AttachmentList
              className={clsx(classes.listItem, classes.attachments)}
              items={attachments}
            />
          </Accordion>
        </ListItem>
      )}
    </>
  );
};

ProductLotSection.displayName = 'ProductLotSection';

export default ProductLotSection;
