/*
Author: Paolo Furini
Component: src/components/ProducerPage/ProducerPage.tsx
*/

import { FunctionComponent } from 'react';
import PageHeader from 'components/PageHeader/PageHeader';
import List from '@material-ui/core/List';
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import CompanySection from 'components/CompanySection';
import RemoteHtmlSection from 'components/RemoteHtmlSection';
import useMapProps from 'hooks/useMapProps';
import FullscreenProgress from 'components/FullscreenProgress';
import FullscreenError from 'components/FullscreenError';
import useCompany from '../../hooks/useCompany';

export interface CompanyPageProps {
  // TODO: props definition here
}

const CompanyPage: FunctionComponent<CompanyPageProps> = () => {
  const { path, url } = useRouteMatch();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const mapProps = useMapProps();

  const { data, error, loading } = useCompany(id, path);

  function navigateToDetails() {
    history.push(`${url}/details`);
  }

  return (
    <>
      {loading && <FullscreenProgress />}
      {error && <FullscreenError error={error} />}
      {data && !loading && (
        <>
          <PageHeader title={data.name} imageUrl={data.imageUrl} />
          <List component="main">
            {data.descriptionUrl ? (
              <Switch>
                <Route
                  path={`${path}/details`}
                  render={() => (
                    <RemoteHtmlSection documentUrl={data.descriptionUrl!} />
                  )}
                />
                <Route
                  path={`${path}`}
                  render={() => (
                    <CompanySection
                      company={data}
                      onDetailsClick={navigateToDetails}
                      mapProps={mapProps}
                    />
                  )}
                />
              </Switch>
            ) : (
              <CompanySection company={data} mapProps={mapProps} />
            )}
          </List>
        </>
      )}
    </>
  );
};

CompanyPage.displayName = 'ProducerPage';

export default CompanyPage;
