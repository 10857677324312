/*
Author: Paolo Furini
Component: src/components/AvatarTitleSubtitle/AvatarTitleSubtitle.tsx
*/

import { FunctionComponent, ReactNode } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

type AvatarAlign = 'top' | 'center' | 'bottom';

type StyleProps = { avatarAlign?: AvatarAlign };

export interface AvatarTitleSubtitleProps {
  avatar: ReactNode;
  avatarSize?: number;
  avatarAlign?: AvatarAlign;
  title: string;
  subtitle?: string;
}

function toAlignSelf(avatarAlign?: AvatarAlign): string {
  switch (avatarAlign) {
    case 'top':
      return 'flex-start';
    case 'bottom':
      return 'flex-end';
  }
  return 'center';
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    avatar: {
      alignSelf: (props: StyleProps) => toAlignSelf(props.avatarAlign),
      minWidth: 'auto',
      paddingRight: theme.spacing(2),
    },
    text: {
      marginTop: 0,
      marginBottom: 0,
    },
    title: {
      lineHeight: 1,
      paddingBottom: theme.spacing(1),
    },
    subtitle: {
      lineHeight: 1,
    },
  }),
);

const AvatarTitleSubtitle: FunctionComponent<AvatarTitleSubtitleProps> = ({
  avatar,
  avatarSize = 40,
  avatarAlign = 'center',
  title,
  subtitle,
}) => {
  const classes = useStyles({ avatarAlign });

  return (
    <ListItem
      component="div"
      alignItems="center"
      className={classes.root}
      disableGutters
    >
      {avatar && (
        <ListItemAvatar className={classes.avatar}>
          <Avatar
            alt={title}
            src={typeof avatar === 'string' ? avatar : undefined}
            style={{ width: avatarSize, height: avatarSize }}
          >
            {typeof avatar !== 'string' && avatar}
          </Avatar>
        </ListItemAvatar>
      )}
      <ListItemText
        primary={title}
        secondary={subtitle}
        secondaryTypographyProps={{ variant: 'body2' }}
        classes={{
          root: classes.text,
          primary: classes.title,
          secondary: classes.subtitle,
        }}
      />
    </ListItem>
  );
};
AvatarTitleSubtitle.displayName = 'AvatarTitleSubtitle';

export default AvatarTitleSubtitle;
