import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 1024,
      xl: 1400,
    },
  },
  palette: {
    primary: {
      main: '#2196F3',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    h1: {
      fontWeight: 300,
      fontSize: '2.25rem',
      lineHeight: 1.12,
      letterSpacing: '-0.04175em',
    },
    h2: {
      fontWeight: 300,
      fontSize: '1.75rem',
      lineHeight: 1.05,
      letterSpacing: '-0.00833em',
    },
    h3: {
      fontWeight: 300,
      fontSize: '1.5rem',
      lineHeight: 1.12,
      letterSpacing: '0em',
    },
    h4: {
      fontWeight: 400,
      fontSize: '1.25rem',
      lineHeight: 1.05,
      letterSpacing: '0.00833em',
    },
  },
});

export default theme;
