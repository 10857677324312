/*
Author: Paolo Furini
Component: src/components/TrackingStep/TrackingStep.tsx
*/

import { CSSProperties, FunctionComponent, ReactElement } from 'react';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { PaletteColor } from '@material-ui/core/styles/createPalette';
import Divider from '@material-ui/core/Divider';
import SubjectDetails, {
  SubjectDetailsProps,
  MapProps as TMapProps,
} from 'components/SubjectDetails';
import clsx from 'clsx';

export type MapProps = TMapProps;

export interface TrackingStepProps {
  name: string;
  icon?: ReactElement;
  avatarColor?: string | PaletteColor;
  date: string;
  subject?: Omit<SubjectDetailsProps, 'onClick'>;
  onSubjectClick?: () => void;
  className?: string;
  style?: CSSProperties;
  mapProps?: MapProps;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      gridTemplateColumns: 'min-content auto',
      gridTemplateRows: 'min-content 1fr',
      gridTemplateAreas: '"icon heading" "line details"',
      gap: `0 ${theme.spacing(2)}px`,
    },
    icon: {
      gridArea: 'icon',
      placeSelf: 'stretch center',
    },
    heading: {
      gridArea: 'heading',
      alignSelf: 'center',
      display: 'grid',
      gridTemplateColumns: '1fr min-content auto',
      gridTemplateRows: 'auto',
      gap: `0px ${theme.spacing(0.5)}px`,
      gridTemplateAreas: '"name dateIcon date"',
      alignItems: 'self-end',
    },
    name: {
      gridArea: 'name',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    dateIcon: {
      gridArea: 'dateIcon',
      color: theme.palette.text.secondary,
      display: 'inline-flex',
      verticalAlign: 'top',
    },
    date: {
      gridArea: 'date',
    },
    line: {
      gridArea: 'line',
      placeSelf: 'stretch center',
    },
    details: {
      gridArea: 'details',
      paddingBottom: theme.spacing(1),
    },
    divider: {
      borderLeft: `1px dashed ${theme.palette.divider}`,
    },
  }),
);

const TrackingStep: FunctionComponent<TrackingStepProps> = ({
  name,
  icon,
  avatarColor,
  date,
  subject,
  onSubjectClick,
  className,
  style,
  mapProps,
}) => {
  const classes = useStyles();
  const color =
    typeof avatarColor === 'string' ? avatarColor : avatarColor?.light;

  return (
    <div className={clsx(classes.root, className)} style={style}>
      <div className={classes.icon}>
        <Avatar style={color ? { backgroundColor: color } : undefined}>
          {icon ?? <EventAvailableIcon />}
        </Avatar>
      </div>
      <div className={classes.heading}>
        <div className={classes.name}>
          <Typography variant="h3" noWrap>
            {name}
          </Typography>
        </div>
        <div className={classes.dateIcon}>
          <EventOutlinedIcon />
        </div>
        <div className={classes.date}>
          <Typography>{date}</Typography>
        </div>
      </div>
      <div className={classes.line}>
        <Divider className={classes.divider} orientation="vertical" />
      </div>
      {subject && (
        <div className={classes.details}>
          <SubjectDetails
            mapProps={mapProps}
            {...subject}
            onClick={onSubjectClick}
          />
        </div>
      )}
    </div>
  );
};

TrackingStep.displayName = 'TrackingStep';

export default TrackingStep;
