/* eslint-disable import/no-extraneous-dependencies */
import { JsonRpcProvider } from '@ethersproject/providers';
import { ethers, BigNumber } from 'ethers';
import { ConnectionInfo, ParamType } from 'ethers/lib/utils';
import axios from 'axios';
import { BlockchainService } from '../blockchainService';
import ABI from './abi/BioSociale.json';
import { Lot, LotMetadata, MetadataType } from '../../models/lot';
import { Company } from '../../models/company';
import { Product } from '../../models/product';
import { Place } from '../../models/place';
import {
  abiDecodeDate,
  BioSocialeContract,
  decodePlaceType,
  deHexify,
  toUtf8String,
} from '../bioSocialeContract';
import { GeoPoint } from '../geoPoint';
import AbiStructs from './abi/abiStructs';
import { emptyToNull } from '../../helpers/stringUtils';
import { DateType } from '../../models/dateType';

const OAUTH2_SCOPES =
  'rpc://web3_* rpc://net_* rpc://eth_chainId rpc://eth_networkId rpc://eth_protocolVersion ' +
  'rpc://eth_call rpc://eth_getLogs rpc://eth_getFilterLogs rpc://eth_newFilter ' +
  'rpc://eth_getFilterChanges rpc://eth_uninstallFilter';

const TOKEN_STORAGE_KEY = '__bs_rpc_t';

const NETWORK_INFO = {
  chainId: parseInt(process.env.REACT_APP_ETH_NETWORK_ID!, 10),
  name: 'BioSociale',
};

export class QuorumBlockchainService implements BlockchainService {
  private _contract: BioSocialeContract | undefined;

  async getLot(hash: string): Promise<Lot> {
    const contract = await this.getContract();

    const lotEvent = await contract.getEventByHash('LottoMemorizzato', hash);

    const {
      hashLuogoProduzione,
      hashMagSpedizione,
      hashMagConsegna,
      hashProdotto,
      hashFornitore,
    } = lotEvent.args!;

    const supplier = await this.getSupplier(deHexify(hashFornitore));
    const prodPlace = await this.getPlace(deHexify(hashLuogoProduzione));
    const shipPlace = await this.getPlace(deHexify(hashMagSpedizione));
    const deliveryPlace = await this.getPlace(deHexify(hashMagConsegna));
    const product = await this.getProduct(deHexify(hashProdotto));

    return QuorumBlockchainService.decodeLot(
      lotEvent,
      supplier,
      product,
      prodPlace,
      shipPlace,
      deliveryPlace,
    );
  }

  async getProduct(hash: string): Promise<Product> {
    const contract = await this.getContract();
    const event = await contract.getEventByHash('ProdottoMemorizzato', hash);

    const { hashProduttore } = event.args!;
    const producer = await this.getProducer(deHexify(hashProduttore));

    return QuorumBlockchainService.decodeProduct(event, producer);
  }

  async getPlace(hash: string): Promise<Place> {
    const contract = await this.getContract();
    const event = await contract.getEventByHash('LuogoMemorizzato', hash);
    return QuorumBlockchainService.decodePlace(event);
  }

  async getPlaces(companyHash: string): Promise<Place[]> {
    const contract = await this.getContract();
    const [totalPlaces] = await contract.functions.numeroLuoghiAzienda(
      `0x${companyHash}`,
    );
    if (!totalPlaces.isZero()) {
      let promises = [];
      for (let i = totalPlaces.toBigInt() - BigInt(1); i >= 0; i -= BigInt(1)) {
        promises.push(
          contract.functions.luogoAzienda(
            `0x${companyHash}`,
            BigNumber.from(i),
          ),
        );
      }
      const hashes = await Promise.all(promises);
      promises = hashes.map((placeHash) => this.getPlace(placeHash[0]));
      return Promise.all(promises);
    }
    return [];
  }

  async getProducer(hash: string): Promise<Company> {
    const contract = await this.getContract();
    const event = await contract.getEventByHash('ProduttoreMemorizzato', hash);

    // get all the places associated with this company (from the blockchain)
    const places = await this.getPlaces(hash);

    return QuorumBlockchainService.decodeCompany(event, places);
  }

  async getSupplier(hash: string): Promise<Company> {
    const contract = await this.getContract();
    const event = await contract.getEventByHash('FornitoreMemorizzato', hash);

    // get all the places associated with this company (from the blockchain)
    const places = await this.getPlaces(hash);

    return QuorumBlockchainService.decodeCompany(event, places);
  }

  static decodeLot(
    event: ethers.Event,
    supplier: Company,
    product: Product,
    preparePlace: Place,
    shipPlace: Place,
    deliveryPlace: Place,
  ): Lot {
    const { lotto } = event.args!;
    const decodedLot = ethers.utils.defaultAbiCoder.decode(
      AbiStructs.Lotto as ParamType[],
      lotto,
    )[0];

    return {
      id: this.extractHashFromEvent(event),
      number: decodedLot.numero,
      quantity: decodedLot.quantita / 100,
      ddtNumber: emptyToNull(decodedLot.numeroDdt),
      ddtUrl: emptyToNull(decodedLot.urlDdt),
      metadata: this.decodeMetadata(decodedLot.metadata),
      prepareDate: new DateType(abiDecodeDate(decodedLot.dataProduzione)),
      shipDate: new DateType(abiDecodeDate(decodedLot.dataSpedizione)),
      deliveryDate: new DateType(abiDecodeDate(decodedLot.dataConsegna)),
      supplier,
      product,
      preparePlace,
      shipPlace,
      deliveryPlace,
    };
  }

  static decodeProduct(event: ethers.Event, producer: Company): Product {
    const { prodotto } = event.args!;

    const decodedProduct = ethers.utils.defaultAbiCoder.decode(
      AbiStructs.Prodotto as ParamType[],
      prodotto,
    )[0];

    return {
      id: this.extractHashFromEvent(event),
      name: decodedProduct.nome,
      summary: decodedProduct.descrizione,
      categoryDesc: emptyToNull(decodedProduct.descrizioneCategoria),
      descriptionUrl: emptyToNull(decodedProduct.urlDescrizioneEstesa),
      imageUrl: emptyToNull(decodedProduct.urlImmagine),
      category: emptyToNull(toUtf8String(decodedProduct.categoria)),
      producer,
    };
  }

  static decodeCompany(event: ethers.Event, places: Place[] = []): Company {
    const { azienda } = event.args!;

    const decodedCompany = ethers.utils.defaultAbiCoder.decode(
      AbiStructs.Azienda as ParamType[],
      azienda,
    )[0];

    return {
      id: this.extractHashFromEvent(event),
      name: decodedCompany.ragioneSociale,
      summary: decodedCompany.descrizione,
      address: emptyToNull(decodedCompany.sedeLegale),
      descriptionUrl: emptyToNull(decodedCompany.urlDescrizioneEstesa),
      imageUrl: emptyToNull(decodedCompany.urlImmagine),
      logoUrl: emptyToNull(decodedCompany.urlLogo),
      certificationUrl: emptyToNull(decodedCompany.urlCertificazione),
      taxId: emptyToNull(toUtf8String(decodedCompany.codiceFiscale)),
      vatId: emptyToNull(toUtf8String(decodedCompany.partitaIva)),
      places,
    };
  }

  static decodePlace(event: ethers.Event): Place {
    const { luogo } = event.args!;

    const decodedPlace = ethers.utils.defaultAbiCoder.decode(
      AbiStructs.Luogo as ParamType[],
      luogo,
    )[0];

    return {
      name: decodedPlace.nome,
      address: decodedPlace.indirizzo,
      imageUrl: emptyToNull(decodedPlace.urlImmagine),
      type: decodePlaceType(decodedPlace.tipo),
      coords: new GeoPoint(
        decodedPlace.lat / 1000000,
        decodedPlace.lon / 1000000,
      ).toString(),
    };
  }

  static decodeMetadata(metadataJSON?: string): LotMetadata[] {
    const json = metadataJSON || '[]';
    try {
      const parsedArr = JSON.parse(json);
      if (!Array.isArray(parsedArr)) return [];
      return parsedArr.map(
        (meta: {
          codice: string;
          nome: string;
          valore: string;
          tipo?: MetadataType;
        }) => ({
          code: meta.codice,
          name: meta.nome,
          value: meta.valore,
          type: meta.tipo,
        }),
      );
    } catch (e) {
      return [];
    }
  }

  static extractHashFromEvent(event: ethers.Event): string {
    return deHexify(event.topics[1]);
  }

  private async getContract(): Promise<BioSocialeContract> {
    if (process.env.REACT_APP_ETH_RPC_AUTH_ENABLED) {
      const {
        fresh,
        connection,
      } = await QuorumBlockchainService.authenticate();
      if (fresh) {
        this._contract = new BioSocialeContract(
          process.env.REACT_APP_ETH_CONTRACT_ADDR!,
          ABI,
          new JsonRpcProvider(connection, NETWORK_INFO),
        );
      }
    } else if (this._contract == null) {
      this._contract = new BioSocialeContract(
        process.env.REACT_APP_ETH_CONTRACT_ADDR!,
        ABI,
        new JsonRpcProvider(process.env.REACT_APP_ETH_RPC_URL!, NETWORK_INFO),
      );
    }
    return this._contract!;
  }

  private static async authenticate(): Promise<{
    fresh: boolean;
    connection: ConnectionInfo;
  }> {
    function buildConnInfo(bearer: string) {
      return {
        url: process.env.REACT_APP_ETH_RPC_URL!,
        headers: {
          Authorization: `Bearer ${bearer}`,
        },
      };
    }

    // get the token from session storage
    const tokenStr = sessionStorage.getItem(TOKEN_STORAGE_KEY);
    if (tokenStr) {
      const token = JSON.parse(tokenStr);
      const expMs = new Date(token.exp);
      if (new Date() < expMs) {
        return {
          fresh: false,
          connection: buildConnInfo(token.t),
        };
      }
    }

    const params = new URLSearchParams();
    params.append('client_id', process.env.REACT_APP_ETH_RPC_OAUTH2_CLIENT_ID!);
    params.append(
      'client_secret',
      process.env.REACT_APP_ETH_RPC_OAUTH2_CLIENT_SECRET!,
    );
    params.append('grant_type', 'client_credentials');
    params.append('scope', OAUTH2_SCOPES);
    const resp = await axios.post(
      process.env.REACT_APP_ETH_RPC_OAUTH2_TOKEN_URL!,
      params,
    );

    const { data } = resp;
    if (data.token_type === 'Bearer') {
      const token = {
        exp: new Date().getTime() + data.expires_in * 1000,
        t: data.access_token,
      };
      // save the token in session storage
      sessionStorage.setItem(TOKEN_STORAGE_KEY, JSON.stringify(token));
      return {
        fresh: true,
        connection: buildConnInfo(token.t),
      };
    }

    throw new Error('Invalid token response');
  }
}
