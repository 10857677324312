/* eslint-disable import/no-extraneous-dependencies */
import { BytesLike, Contract, ethers } from 'ethers';
import { arrayify, hexlify } from '@ethersproject/bytes';
import { PlaceType } from '../models/place';

export type Events = 'LottoMemorizzato' | 'FornitoreMemorizzato' | 'ProduttoreMemorizzato'
| 'ProdottoMemorizzato' | 'LuogoMemorizzato';

export function toUtf8String(bytesLike: BytesLike): string {
  const data = arrayify(bytesLike);
  // Find the null termination
  let { length } = data;
  while (data[length - 1] === 0) {
    length--;
  }
  return ethers.utils.toUtf8String(data.slice(0, length));
}

export function abiDecodeDate(encodedDate: string): Date {
  return new Date(toUtf8String(encodedDate));
}

export function decodePlaceType(bytesLike: BytesLike): PlaceType {
  return toUtf8String(bytesLike) as PlaceType;
}

export function deHexify(hexString: string): string {
  return hexString.startsWith('0x') ? hexString.slice(2) : hexString;
}

export class BioSocialeContract extends Contract {

  async getEventByHash(eventName: Events, hash: string): Promise<ethers.Event> {
    const filter = this.filters[eventName](
      hexlify(hash, { allowMissingPrefix: true }),
    );
    const events = await this.queryFilter(filter);
    if (events && events.length > 0) {
      return events[events.length - 1];
    }
    throw new Error('La tua richiesta non ha prodotto risultati in Blockchain');
  }
}
