const messages = {
  AttachmentListItemData_attachments_aria: 'Documenti allegati',
  AttachmentListItem_open_aria: 'Apri',
  BackButton_back_aria: 'Indietro',
  CompanyCard_businessAddress: 'Sede Legale',
  CompanyCard_businessName: 'Rag. Sociale',
  CompanyCard_taxId: 'Codice Fiscale',
  CompanyCard_vatId: 'Partita IVA',
  CompanySection_attachments: 'Allegati',
  CompanySection_companyDetails: 'Dati Azienda',
  CompanySection_places: 'Luoghi',
  CompanySection_producerCard: 'Scheda Produttore',
  CompanySection_qualityCertification: 'Certificato di Qualità',
  CompositionListItem_details_aria: 'Dettagli',
  CompositionList_productComposition: 'Composizione prodotto',
  DetailsCard_details: 'Dettagli',
  MapThumbnail_close_aria: 'Chiudi',
  OrderItemPage_attachments: 'Allegati',
  OrderItemPage_composition: 'Composizione',
  OrderItemPage_ddtt: 'Documento di Trasporto',
  OrderItemPage_delivery: 'Consegna',
  OrderItemPage_preparation: 'Preparazione',
  OrderItemPage_shipment: 'Spedizione',
  OrderItemPage_tracking: 'Tracciatura',
  OrderItemPage_whatYouAreLookingAt: 'Cosa stai guardando',
  OrderItemPage_whatYouAreLookingAt_content:
    '<b>{product}</b> preparato da <b>{supplier}</b> il <b>{prepareDate}</b> (ordine numero <b>{order}</b>) e consegnato il <b>{deliveryDate}</b>.',
  PlaceListItem_address_aria: 'Indirizzo',
  PlaceListItem_name_aria: 'Nome',
  PlaceListItem_place_aria: 'Luogo',
  PlaceListItem_place_ariadesc: "Luogo associato all'azienda",
  PlaceList_places_aria: 'Luoghi',
  PlaceList_places_ariadesc: "Lista dei luoghi associati all'azienda",
  ProductLotSection_attachments: 'Allegati',
  ProductLotSection_date: 'Data',
  ProductLotSection_ddt: 'Documento di Trasporto',
  ProductLotSection_ddtNumber: 'Numero DDT',
  ProductLotSection_category: 'Categoria',
  ProductLotSection_delivery: 'Consegna',
  ProductLotSection_expireDate: 'Data Scadenza',
  ProductLotSection_harvesting: 'Raccolta',
  ProductLotSection_lotNumber: 'Lotto #{lotNumber}',
  ProductLotSection_producer: 'Produttore',
  ProductLotSection_productCard: 'Scheda Prodotto',
  ProductLotSection_tracking: 'Tracciatura',
  ProductLotSection_warehousing: 'Stoccaggio',
  SubjectDetails_details_aria: 'Dettagli',
  error_400: 'Il codice richiesto non è valido.',
  error_404: 'Il codice richiesto non esiste.',
};

export default messages;
