/*
Author: Paolo Furini
Component: src/components/CompositionListItem/CompositionListItem.tsx
*/

import { FunctionComponent } from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import ImageIcon from '@material-ui/icons/ImageOutlined';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import { useIntl } from 'react-intl';

export interface CompositionListItemProps {
  imageUrl?: string;
  title: string;
  subtitle?: string;
  onClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    listItemAvatar: {
      minWidth: 55 + theme.spacing(2),
    },
    avatar: {
      color: theme.palette.getContrastText(theme.palette.grey[400]),
      backgroundColor: theme.palette.grey[400],
      width: 55,
      height: 55,
    },
    text: {
      marginTop: 0,
      marginBottom: 0,
      marginRight: -theme.spacing(2),
    },
  }),
);

const CompositionListItem: FunctionComponent<CompositionListItemProps> = ({
  imageUrl,
  title,
  subtitle,
  onClick,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const theme = useTheme();

  const typoProps = {
    noWrap: true,
    style: { maxWidth: `calc(100% - ${theme.spacing(2)}px)` },
  };

  return (
    <ListItem
      alignItems="center"
      className={classes.root}
      disableGutters
      divider
    >
      <ListItemAvatar className={classes.listItemAvatar}>
        <Avatar
          alt={title}
          src={imageUrl}
          variant="square"
          className={classes.avatar}
        >
          {!imageUrl && <ImageIcon />}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        className={classes.text}
        primary={title}
        secondary={subtitle}
        primaryTypographyProps={typoProps}
        secondaryTypographyProps={typoProps}
      />
      <ListItemSecondaryAction>
        <IconButton
          color="primary"
          edge="end"
          aria-label={intl.formatMessage({
            id: 'CompositionListItem_details_aria',
            defaultMessage: 'Dettagli ',
          })}
          onClick={onClick}
        >
          <ArrowForwardOutlinedIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

CompositionListItem.displayName = 'CompositionListItem';

export default CompositionListItem;
