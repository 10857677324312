/*
Author: Paolo Furini
Component: src/components/Accordion/Accordion.tsx
*/

import { FunctionComponent, ReactElement } from 'react';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion, {
  AccordionProps as MuiAccordionProps,
} from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export interface AccordionProps extends MuiAccordionProps {
  id?: string;
  heading: string;
  icon?: ReactElement;
}

const AccordionRoot = withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    flexGrow: 1,
  },
}))(MuiAccordionDetails);

const Accordion: FunctionComponent<AccordionProps> = (props) => {
  const { id, heading, icon, children, ...accordionProps } = props;

  return (
    <AccordionRoot {...accordionProps}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={id && `${id}-content`}
        id={id}
      >
        <Box pr={2}>{icon}</Box>
        <Typography variant="h2">{heading}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </AccordionRoot>
  );
};

Accordion.displayName = 'Accordion';

export default Accordion;
