/*
Author: Paolo Furini
Component: src/components/BackButton/BackButton.tsx
*/

import { common } from '@material-ui/core/colors';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

const BackButton = (props: IconButtonProps) => {
  const history = useHistory();
  const intl = useIntl();

  function handleClick() {
    history.go(-1);
  }

  return history && history.length ? (
    <IconButton
      onClick={handleClick}
      aria-label={intl.formatMessage({
        id: 'BackButton_back_aria',
        defaultMessage: 'Indietro',
      })}
      {...props}
    >
      <ArrowBackIosIcon style={{ color: common.white }} />
    </IconButton>
  ) : null;
};

export default BackButton;
