/*
Author: Paolo Furini
Component: src/components/CompanyCard/CompanyCard.tsx
*/

import React, { FunctionComponent } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core';
import { useIntl } from 'react-intl';
import MapWithPin, { MapProps as TMapProps } from '../MapWithPin';
import AvatarTitleSubtitle from '../AvatarTitleSubtitle';

export type MapProps = TMapProps;

export interface CompanyCardProps {
  name: string;
  address?: string;
  addressPoint?: { lat: number; lng: number };
  vatId?: string;
  taxId?: string;
  logoUrl?: string;
  mapProps?: MapProps;
}

const CompanyCard: FunctionComponent<CompanyCardProps> = ({
  name,
  address,
  addressPoint,
  vatId,
  taxId,
  logoUrl,
  mapProps,
}) => {
  const theme = useTheme();
  const intl = useIntl();

  return (
    // TODO: nowrap does not work on Typography (inline span), it should be on the flex container (div), and also the font properties and the text should be plain without span
    <Grid container spacing={2}>
      {!addressPoint && (
        <Grid
          item
          container
          xs={12}
          sm={6}
          spacing={1}
          wrap="nowrap"
          zeroMinWidth
        >
          <Grid item xs={4} zeroMinWidth>
            <Typography variant="caption" color="textSecondary" noWrap>
              {intl.formatMessage({
                id: 'CompanyCard_businessName',
                defaultMessage: 'Rag. Sociale',
              })}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body2">{name}</Typography>
          </Grid>
        </Grid>
      )}
      {vatId && (
        <Grid
          item
          container
          xs={12}
          sm={6}
          spacing={1}
          wrap="nowrap"
          zeroMinWidth
        >
          <Grid item xs={4} sm={3} zeroMinWidth>
            <Typography variant="caption" color="textSecondary" noWrap>
              {intl.formatMessage({
                id: 'CompanyCard_vatId',
                defaultMessage: 'Partita IVA',
              })}
            </Typography>
          </Grid>
          <Grid item xs={8} sm={9}>
            <Typography variant="body2">{vatId}</Typography>
          </Grid>
        </Grid>
      )}
      {taxId && (
        <Grid
          item
          container
          xs={12}
          sm={6}
          spacing={1}
          wrap="nowrap"
          zeroMinWidth
        >
          <Grid item xs={4} zeroMinWidth>
            <Typography variant="caption" color="textSecondary" noWrap>
              {intl.formatMessage({
                id: 'CompanyCard_taxId',
                defaultMessage: 'Codice Fiscale',
              })}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body2">{taxId}</Typography>
          </Grid>
        </Grid>
      )}
      {address && (
        <Grid
          item
          container
          xs={12}
          sm={6}
          spacing={1}
          wrap="nowrap"
          zeroMinWidth
        >
          <Grid item xs={4} sm={3} zeroMinWidth>
            <Typography variant="caption" color="textSecondary" noWrap>
              {intl.formatMessage({
                id: 'CompanyCard_businessAddress',
                defaultMessage: 'Sede Legale',
              })}
            </Typography>
          </Grid>
          <Grid item xs={8} sm={9}>
            <Typography variant="body2">{address}</Typography>
          </Grid>
        </Grid>
      )}
      {addressPoint && (
        <Grid item xs={12}>
          <MapWithPin
            center={{ ...addressPoint }}
            zoom={16}
            height={270}
            pinProps={{ width: 260, style: { padding: theme.spacing(1) } }}
            mapProps={mapProps}
          >
            <AvatarTitleSubtitle
              title={name}
              subtitle={address}
              avatar={logoUrl}
              avatarSize={80}
            />
          </MapWithPin>
        </Grid>
      )}
    </Grid>
  );
};

CompanyCard.displayName = 'CompanyCard';

export default CompanyCard;
